<template>
    <div class="bg-gradient-to-tr flex justify-between p-4 rounded-lg shadow-inner shadow-sky-900 items-center gap-5"
         :class="{'from-slate-800/50 to-slate-400':customer,'from-red-800/50 to-red-400 text-red-100':!customer}">
        <div class="flex gap-5 items-center">
            <i class="bi bi-person-circle m-auto text-6xl text-white"></i>
            <div class="flex flex-col gap-2 text-xl text-white">
                <template v-if="customer?.firstname && customer?.lastname">
                    <div class="mt-auto">Prénom : {{ customer.firstname }}</div>
                    <div class="mt-auto">Nom : {{ customer.lastname }}</div>
                </template>
                <template v-else>
                    <div class="text-white">
                        Anonyme
                    </div>
                </template>

            </div>
        </div>
        <div class="text-6xl text-white">
            Solde : {{ getCreditToEuro(customer?.credit) ?? 0 }}
        </div>
    </div>
</template>

<script>
import {getCreditToEuro} from "@/utils/app_const";
export default {
	methods:{
		getCreditToEuro,
  },
	props: {
		customer: {
			type: Object,
		},
	},
	name: "customer_topbar_status"
}
</script>

<style scoped>

</style>
