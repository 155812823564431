<template>
  <div class="p-2 w-full">
    <RouterLink :to="url">
      <div :class="box_class" class="bg-white">
        <i class="text-3xl mx-auto" :class="icon" v-if="icon"></i>
        <img v-if="img" :src="img" class="w-max w-16 mx-auto" :alt="title">
        <div class="text-2xl mx-auto mt-4 text-center">{{ title }}</div>
      </div>
    </RouterLink>
  </div>
</template>

<script>
import {RouterLink} from "vue-router";

export default {
  name: "MenuItem",
  components:{
    RouterLink,
  },
  props:{
    icon: {
      require: false,
      type: String
    },
    img:{
      require: false,
      type: String
    },
    box_class:{
      require: false,
      type: String,
      default: "border border-neutral-400 border-3 rounded-xl shadow-lg flex flex-col p-3 transition ease-in-out hover:ring-drift hover:ring-2"
    },
    title: String,
    url: String,
  }
}
</script>

<style scoped>

</style>
