<template>
  <div class="container mx-auto py-10">
    <div class="text-center flex flex-col gap-10 text-xl lg:w-1/2 mx-auto">
      <template v-for="(customer,index) in customer_list" :key="customer">

        <div class="flex justify-between items-center">
         <p>{{customer.id}}</p>
         <p>{{customer.firstname}} {{customer.lastname}}</p>
          <button class="btn-success" @click="writeTag({id:customer.id})">Write</button>
        </div>
        <hr class="border-t-neutral-400" v-if="index+1 !== customer_list.length">

      </template>
    </div>
    <hr class="border-t-neutral-800 border-t-6 my-10" v-if="index+1 !== customer_list.length">

    <div class="flex flex-col justify-center items-center">
      <h1 class="text-center text-6xl">
        <span :class="{'text-drift':writing}">{{ writing?'Writing':'...' }}</span>
      </h1>
    </div>

  </div>
</template>

<script>
import {useApiStore} from "@/utils/store/api_store";
import nfc_controller from "@/utils/nfc_controller";

export default {
  async created() {
    const response = await this.makeApiCall("get", "/customers");

    this.customer_list = response.data;
  },
  data(){
    return{
      customer_list: [],
    }
  },
  setup() {
    const apiStore = useApiStore();

    const {makeApiCall} = apiStore;

    return {makeApiCall}
  },
  mixins:[nfc_controller],
  name: "set_user"
}
</script>

<style scoped>

</style>
