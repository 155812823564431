
import html2canvas from 'html2canvas';
export const APP_NAME='Cashless';
export const APP_VERSION='v1.4.2';

export const DEFAULT_URL_QR = 'https://driftcup.eu/cashless/';
export const url_api = '/api';

export const good_password = 'Aqsdfghjklmp'; // En clair, car pas de données sensibles ici
export const TERMINAL_LOCATION_ID = localStorage.getItem('terminal_location_id') ?? null;

export const REPAS_PRICE = localStorage.getItem('repas_price') ?? 900;

export function getCreditToEuro(credit = 0){
	if(!credit || credit === 0) return formatPrice(0);
	if(credit instanceof String){
		credit = parseInt(credit);
	}
    return formatPrice(credit/100);
}

export function formatPrice(price){
	return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(price);
}

export async function BtPrint(element = 'print_create_user') {
	const divElement = document.getElementById(element);

// Use html2canvas to capture the div as an image
	html2canvas(divElement)
		.then(canvas => {
			// Convert the canvas content to a Base64 encoded JPEG image
			const jpeg = canvas.toDataURL('image/jpeg');


			// Print the Base64 string to the console
			console.log(jpeg);


			let beforeUrl = 'rawbt:';
			window.location.href = beforeUrl + jpeg;
		})
		.catch(error => {
			console.error('An error occurred:', error);
		});

}
