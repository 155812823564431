import { createApp } from 'vue'
import App from './App.vue'

import {createRouter,createWebHashHistory} from 'vue-router'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

import {good_password} from "@/utils/app_const";

import PrimeVue from 'primevue/config';

import "primevue/resources/themes/lara-light-indigo/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";

import './css/main.scss'
import 'bootstrap-icons/font/bootstrap-icons.css'
import './registerServiceWorker'

import index_page from './page/index.vue'
import config_basic_page from './page/admin/config/basic.vue'
import config_tpe from "@/page/admin/config/tpe";
import store_list_page from './page/store/store_list.vue'
import store_info_page from './page/store/store_info.vue'
import nfc_reader_page from './page/admin/utils/nfc_reader.vue'
import manage_product_page from './page/store/manage_product.vue'
import set_user from "./page/admin/utils/OLD/set_user";
import create_user from "./page/admin/create_user.vue";
import readUser from "@/page/admin/read-user.vue";
import create_order from "@/page/store/create_order";
import manage_credit from "@/page/admin/manage_credit";
import admin_index from "@/page/admin/index";
import fast_print from "@/page/admin/fast_print.vue";


const routes = [

	{ path: '/', component: index_page , name: 'Cashless',meta: {icon: 'bi bi-house' } },
	{ path: '/store', component:store_list_page  , name: 'Magasin',meta: {icon: 'bi bi-shop-window' } },
	{ path: '/store/:shop_id', component:store_info_page  , name: 'Gestion du magasin',meta: {icon: 'bi bi-shop-window' } },
	{ path: '/store/:shop_id/manage-product', component:manage_product_page  , name: 'Gestion des produits',meta: {icon: 'bi bi-shop-window' } },
	{ path: '/store/:shop_id/create-order', component:create_order  , name: 'Crée une commande',meta: {icon: 'bi bi-basket2' } },

	{ path: '/admin', component: admin_index , name: 'Admin',meta: {icon: 'bi bi-gear-wide-connected' } },
	{ path: '/admin/config', component: config_basic_page , name: 'Configuration',meta: {icon: 'bi bi-gear-wide-connected' },beforeEnter: checkAdminRights },
	{ path: '/admin/tpe', component: config_tpe , name: 'Configuration TPE',meta: {icon: 'bi bi-calculator-fill' },beforeEnter: checkAdminRights },
	{ path: '/admin/read-card', component: readUser , name: 'Lire une carte',meta: {icon: 'bi bi-credit-card-2-front-fill' },beforeEnter: checkAdminRights },
	{ path: '/admin/utils/nfc-reader', component: nfc_reader_page , name: 'Lecteur NFC',meta: {icon: 'bi bi-broadcast' },beforeEnter: checkAdminRights },
	{ path: '/admin/utils/set-user', component: set_user , name: '[DEV] Set user',meta: {icon: 'bi bi-person-circle' },beforeEnter: checkAdminRights },
	{ path: '/admin/utils/create-user', component: create_user , name: 'Initialiser une carte',meta: {icon: 'bi bi-person-fill-add' },beforeEnter: checkAdminRights },
	{ path: '/admin/utils/manage-credit', component: manage_credit , name: 'Ajouter du solde',meta: {icon: 'bi bi-coin' },beforeEnter: checkAdminRights },
	{ path: '/admin/utils/fastprint', component: fast_print , name: 'fastprint',meta: {icon: 'bi bi-qr-code' },beforeEnter: checkAdminRights },


	]

const router = createRouter({
	history: createWebHashHistory(),
	routes,
})

const pinia = createPinia().use(piniaPluginPersistedstate);

createApp(App)
	.use(router)
	.use(pinia)
	.use(PrimeVue)
	.mount('#app')

function checkAdminRights(to,from,next){
	if(localStorage.getItem('drftsh_admin_ok') && localStorage.getItem('drftsh_admin_ok') === good_password){
		next()
	}else{
		next('/admin')
	}
}
