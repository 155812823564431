import QRCode from 'qrcode-js-package/qrcode'
export function makeQrCode(div_id, url = '',size=125){
	document.getElementById(div_id).innerHTML = '';

	new QRCode(div_id, {
		text: url,
		width: size,
		height: size,
	});
}
