<template>
    <div class="my-10 w-1/2 mx-auto">
        <h1 class="text-center text-4xl mb-10">Config TPE</h1>

        <p class="mt-5 mb-2">Location id tpe : <span class="text-neutral-400">( current : {{current_tpe_location ?? "AUCUN"}} )</span></p>
        <div class="flex">
            <input class="input-primary flex-grow rounded-r-none" v-model="location_tpe">
            <button class="btn-primary rounded-l-none" @click="save_location" :disabled="this.location_tpe===''">Save</button>
        </div>
    </div>
</template>

<script>
import {TERMINAL_LOCATION_ID} from "@/utils/app_const";

export default {
    data() {
        return {
            location_tpe: '',
            current_tpe_location: null,
        }
    },
    created() {
      this.current_tpe_location =  TERMINAL_LOCATION_ID;
    },
    methods:{
        save_location() {
            if(this.location_tpe === '') return;

            localStorage.setItem('terminal_location_id', this.location_tpe);
            this.location_tpe = '';
            window.location.reload();
        }
    },
	name: "admin-tpe"
}
</script>
<style scoped>

</style>
