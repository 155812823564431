<template>
    <p :class="{'text-amber-500':state.status === 'error','text-emerald-600':state.status==='success','text-neutral-400':state.status==='pending','opacity-50':!state.active}">
        <i class="bi bi-chat-right-dots-fill " v-if="state.status === 'pending'"></i>
        <i class="bi bi-check-circle-fill" v-if="state.status === 'success'"></i>
        <i class="bi bi-exclamation-circle-fill" v-if="state.status === 'error'"></i>
        <span class="ml-5 font-bold">{{ state.message }}</span>
        <span class="font-bold ml-2" v-if="state.status === 'pending' && state.active">...</span>
    </p>
</template>

<script>export default {
	name: "status_paiment",
	props: ['state']
}
</script>

<style scoped>

</style>
