<template>
    <div class="container mx-auto px-5 py-4">
        <div class="flex items-center justify-center mb-16">
            <h1 class="text-center text-3xl font-bold">
                Configuration API
            </h1>
        </div>


        <div class="flex flex-col mx-auto w-full lg:w-1/2 gap-5">

            <div class="flex flex-col">
        <span class="mb-2 font-medium">Id emplacement <span class="text-blue-600"
                                                            v-if="form.spot_id !== old_form.spot_id">*</span></span>
                <input type="text" placeholder="192.168.1.194:8001" class="input-primary" v-model="form.spot_id">
            </div>

            <div class="flex flex-col">
        <span class="mb-2 font-medium">Serveur Api <span class="text-blue-600"
                                                         v-if="form.server_api !== old_form.server_api">*</span></span>
                <input type="text" placeholder="192.168.1.194:8001" class="input-primary" v-model="form.server_api">
            </div>

            <div class="flex flex-col">
        <span class="mb-2 font-medium">Bearer token <span class="text-blue-600"
                                                          v-if="form.server_api !== old_form.server_api">*</span></span>
                <input type="text" placeholder="192.168.1.194:8001" class="input-primary" v-model="form.bearer_token_api">
            </div>

            <div class="flex flex-wrap gap-4 lg:flex-nowrap">
                <button class="btn-outline-primary bg-white mx-auto w-full" @click="test_api"><i
                        class="bi bi-wifi mr-3"></i>
                    Test de connexion
                </button>
                <button class="btn-outline-primary bg-white mx-auto w-full" @click="resetToDefault">Réinitialiser les
                    paramètres
                    par défaut
                </button>

            </div>
            <hr class="border border-t-neutral-400 my-4">
            <div class="flex flex-col gap-10">
                <button class="btn-success mx-auto w-full lg:w-1/2"
                        @click="modal_update.visible = !modal_update.visible"
                        v-if="connected" :disabled="config_changed"><i class="bi bi-arrow-repeat mr-3"></i>Effectuer une
                    mise à
                    jour
                </button>

                <button class="btn-primary mx-auto w-full lg:w-1/2" @click="save" :disabled="!config_changed">
                    Sauvegarder
                </button>
            </div>

        </div>

        <div class="text-red-700">
            {{ error }}
        </div>


        <Dialog v-model:visible="modal_update.visible" modal :style="{ width: '50vw' }"
                :breakpoints="{ '960px': '75vw', '641px': '100vw' }" :dismissable-mask="true">
            <template #header>
                <div class="w-full">
                    <h1 class="text-2xl font-bold">Mise à jour</h1>
                </div>
            </template>
            <h1 class="text-xl text-drift text-center mb-5">Effectuer une mise à jour va réinitialiser tout paramètre
                modifié
                localement sur la machine, voulez-vous continuer?</h1>
            <div class="flex">
                <button class="btn-primary mx-auto w-full lg:w-1/3" @click="modal_update.visible = false">
                    <i class="bi bi-x-octagon-fill mr-3"></i>Annuler
                </button>
                <button class="btn-success mx-auto w-full lg:w-1/3" @click="update" v-if="connected"
                        :disabled="config_changed">
                    <i class="bi bi-arrow-repeat mr-3"></i>Mettre à jour
                </button>
            </div>
        </Dialog>

    </div>
</template>

<script>
import {useApiStore} from "@/utils/store/api_store";
import {useShopStore} from "@/utils/store/shop_store";
import {storeToRefs} from "pinia/dist/pinia";
import Dialog from 'primevue/dialog';


export default {
	name: "config_basic_page",
	methods: {
		async save() {
			const api_ok = await this.test_api(false);

			if (!api_ok) {
				alert('Impossible de sauvegarder la configuration, connexion à l\'api impossible');
				return;
			}

			this.apiStore.spot_id = this.form.spot_id;
			this.apiStore.url_base = this.form.server_api;
      this.apiStore.bearer_token_api = this.form.bearer_token_api;
			this.old_form = {...this.form};

			const shop_response = await this.fetchShop();

			alert('Configuration sauvegardée');


			if (!shop_response.success) {
				alert(shop_response.message);
			} else {
				this.apiStore.connected = true;
			}


		},
		async update() {
			const api_ok = await this.test_api(false);

			if (!api_ok) {
				alert('Impossible de mettre a jour, connexion à l\'api impossible');
				return;
			}

			const shop_response = await this.fetchShop();

			alert('Données mises à jour');

			if (!shop_response.success) {
				alert(shop_response.message);
			}

			this.modal_update.visible = false

		},

		async resetToDefault() {
			this.apiStore.$reset();
			this.$router.go();
		},

		async test_api(show_alert = true) {
			try {
				const response = await this.testApiUrl(this.form.server_api,this.form.bearer_token_api);
				if (response.status === 200 && response.data === true) {
					if (show_alert) {
						alert('Connexion réussie');
					} else {
						return true;
					}
				}
			} catch (e) {
				this.error = e;
				if (show_alert) {
					alert(e.message);
				} else {
					return false;
				}
			}
			return false;
		},

	},
	computed: {
		config_changed() {
			return JSON.stringify(this.form) !== JSON.stringify(this.old_form);
		}
	},
	data() {
		return {
			form: {
				spot_id: this.spot_id,
				server_api: this.url_base,
				bearer_token: ''
			},
			old_form: {},
			modal_update: {
				visible: false
			},
			error: ''
		}
	},
	created() {
		this.old_form = {...this.form}
	},
	setup() {
		const apiStore = useApiStore();
		const shopStore = useShopStore();

		const {spot_id, url_base, connected,bearer_token_api} = storeToRefs(apiStore);
		const {testApiUrl} = apiStore;
		const {fetchShop} = shopStore;

		return {apiStore, spot_id, url_base, connected,bearer_token_api, testApiUrl, fetchShop}
	},
	components: {
		Dialog
	}
}
</script>

<style scoped>

</style>
