<template>
    <div class="py-8 px-5 h-full">
        <div class="h-full">

            <customer_topbar_status :customer="customer"></customer_topbar_status>

            <div class="flex justify-between my-5 gap-5">


                <div class="flex flex-col w-1/2 gap-5 h-full">

                    <div v-if="!customer"
                         class="bg-gradient-to-tr from-slate-200 to-slate-300/20 rounded-xl shadow-inner shadow-slate-400/50 p-5 flex flex-col items-center justify-center">
                        <button class="btn-primary  text-4xl p-5 mt-10 w-full" @click="scanUser">
                            <span v-if="!reading">Démarrer le scan</span>
                            <span v-else>Annuler le scan</span>
                        </button>

                        <h1 class="text-drift text-2xl text-center my-8 font-medium mx-auto">

                            <template v-if="reading">En attente d'une carte driftcup ...</template>
                            <template v-else>...</template>

                        </h1>

                    </div>
                    <div class="bg-gradient-to-tr from-slate-200 to-slate-300/20 rounded-xl shadow-inner shadow-slate-400/50 p-5"
                         v-else-if="!order_sended_to_tpe">
                        <p class="text-3xl text-center  font-light">Ajouter des crédits</p>
                        <hr class="border border-neutral-300 my-5">
                        <div class="flex flex-col gap-5 ">
                            <div class="flex w-1/2 mx-auto justify-center items-center bg-white  rounded-xl">
                                <input type="number" placeholder="0" id="input_add_credit"
                                       class="py-2 px-5 text-5xl text-center flex-grow w-full  rounded-xl rounded-r-none focus:outline-0"
                                       v-model="price_to_add" v-on:keyup.enter="unfocus">
                                <div class="text-bold text-center text-5xl px-3 rounded-xl ">
                                    €
                                </div>
                            </div>

                            <div class="flex flex-col justify-between items-center  mt-3 gap-5">

                                <div class="flex justify-between items-center gap-2 w-full">
                                    <button class="py-2 w-full transition bg-sky-500 text-white font-bold rounded-lg hover:bg-sky-400 disabled:opacity-25"
                                            @click="modal_cash.visible = true"
                                            :disabled="!price_to_add || price_to_add <= min_price">
                                        Le client a payé en espèce
                                    </button>
                                    <button class="py-2 w-full transition bg-purple-500 text-white font-bold rounded-lg hover:bg-purple-400 disabled:opacity-25"
                                            @click="addCreditCB" :disabled="!price_to_add || price_to_add <= min_price">
                                        Envoyer au TPE
                                    </button>
                                </div>


                                <button class="py-2 w-1/2  transition bg-amber-500 text-white font-bold rounded-lg hover:bg-amber-400 disabled:opacity-25"
                                        @click="modal_free.visible = true"
                                        :disabled="!price_to_add || price_to_add <= min_price">
                                    Offrir
                                </button>
                            </div>

                        </div>
                    </div>

                    <div class="bg-gradient-to-tr from-slate-200 to-slate-300/20 rounded-xl shadow-inner shadow-slate-400/50 p-5 flex flex-col"
                         :class="{'hidden':!customer || !order_sended_to_tpe}">
                        <p class="text-3xl text-center font-light mb-7">BL</p>

                        <ticket_bl :customer="customer" :order="order" :pay_method="pay_method"></ticket_bl>

                    </div>

                    <terminal_status :terminal_is_connected="terminal_is_connected"
                                     :min_price="min_price"></terminal_status>
                </div>
                <div class="bg-gradient-to-tr from-slate-200 to-slate-300/20 w-1/2 rounded-xl shadow-inner shadow-slate-400/50 p-5 h-full">
                    <p class="text-3xl text-center  font-light">Étapes de la commande</p>
                    <hr class="border border-neutral-300 my-5">
                    <div class="flex flex-col gap-10 text-3xl w-2/3 mx-auto">

                        <div v-for="state in states_list" :key="state">
                            <status_paiment :state="state"></status_paiment>
                        </div>
                        <div v-if="states_list[states_list.length - 1].active === true" class="flex">
                            <button @click="refreshPage"
                                    class="mx-auto text-white font-bold bg-sky-500 p-3 w-1/2 rounded-lg transition hover:bg-sky-400">
                                Terminer
                            </button>
                        </div>

                    </div>
                </div>
            </div>


        </div>

        <Dialog v-model:visible="modal_cash.visible" modal :style="{ width: '50vw' }"
                :breakpoints="{ '960px': '75vw', '641px': '100vw' }" :dismissable-mask="true">
            <template #header>
                <div class="w-full">
                    <h1 class="text-2xl font-bold">Confirmation</h1>
                </div>
            </template>
            <h1 class="text-xl text-drift text-center mb-5">Confirmer que le client a bien payé
                {{ formatPrice(price_to_add) }}</h1>
            <div class="flex">
                <button class="btn-primary mx-auto w-full lg:w-1/3" @click="modal_cash.visible = false">
                    NON
                </button>
                <button class="btn-success mx-auto w-full lg:w-1/3" @click="addCreditCASH"
                >
                    OUI
                </button>
            </div>
        </Dialog>
        <Dialog v-model:visible="modal_free.visible" modal :style="{ width: '50vw' }"
                :breakpoints="{ '960px': '75vw', '641px': '100vw' }" :dismissable-mask="true">
            <template #header>
                <div class="w-full">
                    <h1 class="text-2xl font-bold">Confirmation</h1>
                </div>
            </template>
            <h1 class="text-xl text-drift text-center mb-5">Confirmer offrir {{ formatPrice(price_to_add) }} ?</h1>
            <div class="flex">
                <button class="btn-primary mx-auto w-full lg:w-1/3" @click="modal_free.visible = false">
                    NON
                </button>
                <button class="btn-success bg-amber-500 hover:bg-amber-600 mx-auto w-full lg:w-1/3"
                        @click="addCreditFREE">
                    OUI
                </button>
            </div>
        </Dialog>

    </div>
</template>

<script>
import nfc_controller from "@/utils/nfc_controller";
import {useApiStore} from "@/utils/store/api_store";
import {storeToRefs} from "pinia/dist/pinia";
import stripe from "@/utils/stripe";
import status_paiment from "@/components/prefabs/status_paiment.vue";
import {formatPrice, getCreditToEuro,BtPrint, TERMINAL_LOCATION_ID,DEFAULT_URL_QR} from "@/utils/app_const";
import terminal_status from "@/components/admin/terminal_status.vue";
import ticket_bl from "@/components/admin/manage_credit/ticket_bl.vue";
import {makeQrCode} from "@/utils/qr";
import customer_topbar_status from "@/components/admin/manage_credit/customer_topbar_status.vue";
import Dialog from "primevue/dialog";

export default {
	components: {customer_topbar_status, ticket_bl, terminal_status, status_paiment, Dialog},
	methods: {
      BtPrint,
		formatPrice,
		getCreditToEuro,
		unfocus() {
			document.activeElement.blur();
		},
		async scanUser() {
			let response = await this.ToggleScanUser();

			if (response?.success && response?.customer) {
				this.changeState(0, 'success', false);
				this.changeState(1, 'pending', true);

				this.customer = response.customer;
				return;
			}
			if (!response?.success && response?.message) {
				this.changeState(0, 'error', false);
				alert(response.message)
				return;
			}
			this.changeState(0, 'error', false);
			alert('Une erreur est survenue, veuillez réessayer');

		},
		initPaiment() {
			this.order_sended_to_tpe = true;
			document.activeElement.blur();

			console.log(this.customer);
			if (this.customer?.cardId) {
				makeQrCode("qrcode-ticket", DEFAULT_URL_QR + this.customer.cardId,130);
			}
		},
		async addCreditFREE() {
			this.modal_free.visible = false;
			this.initPaiment()
			this.changeState(1, 'success', false);
			this.changeState(2, 'success', false);
			this.changeState(3, 'success', false);
			this.changeState(4, 'success', false);
			this.changeState(5, 'pending', true);

			this.pay_method = 'free';

			await this.UpdateCustomerCredit()
		},
		async addCreditCASH() {
			this.modal_cash.visible = false;
			this.initPaiment()
			this.changeState(1, 'success', false);
			this.changeState(2, 'success', false);
			this.changeState(3, 'success', false);
			this.changeState(4, 'success', false);
			this.changeState(5, 'pending', true);

			this.pay_method = 'cash';

			await this.UpdateCustomerCredit()
		},
		async addCreditCB() {

			this.initPaiment();

			this.changeState(1, 'success', false)
			this.changeState(2, 'pending', true)

			let collectPaymentMethod = null;
			try {
				collectPaymentMethod = await this.createPaimentIntent(this.price_to_add, this.customer?.email ?? null, 'Ajout crédit carte id : ' + this.customer?.card_id)
			} catch (e) {
				this.errorState(2, e)
				return;
			}

			this.changeState(2, 'success', false)
			this.changeState(3, 'pending', true)

			let paymentIntent = null;
			try {
				paymentIntent = await this.processPayment(collectPaymentMethod);
			} catch (e) {
				this.errorState(3, e)
				return;
			}

			this.changeState(3, 'success', false)
			this.changeState(4, 'pending', true)

			let capture = null;
			try {
				capture = await this.capturePaymentIntent(paymentIntent);
			} catch (e) {
				this.errorState(4, e)
				return;
			}

			if (capture.status !== "succeeded") {
				alert("Erreur : " + capture.status);
				this.errorState(4, 'Une erreur est survenue lors de l\'ajout de crédit')
				return;
			}


			this.changeState(4, 'success', false);
			this.changeState(5, 'pending', true);

			this.pay_method = 'cb';

			await this.UpdateCustomerCredit();
		},
		async UpdateCustomerCredit() {
			const response = await this.makeApiCall('post', '/customers/' + this.customer.id + '/add_credit', {
				customer_paid_money: this.price_to_add,
				pay_method: this.pay_method
			});


			if (response.data.success === true && response.data.order) {
				this.order = response.data.order;
				this.changeState(5, 'success', true);
				setTimeout(async () => { // Laisse le temps à la div de s'afficher avant de l'imprimer
					await this.BtPrint('ticket-add-credit-print');
				}, 1000);
				alert('Crédit ajouté avec succès');
				return
			}
			this.changeState(5, 'error', true);

			alert('Une erreur est survenue lors de l\'ajout de crédit');
		},
		changeState(state_id, status = "success", active = true) {
			this.states_list[state_id].status = status;
			this.states_list[state_id].active = active;
		},
		errorState(state_id, error) {
			console.log(error);
			this.states_list[state_id].status = 'error';
			this.states_list[state_id].active = true;
		},
		refreshPage() {
			window.location.reload();
		}
	},
	data() {
		return {
			customer: null,
			price_to_add: null,
			order_sended_to_tpe: false,
			order: null,
			pay_method: null,
			min_price: 0.5,
			modal_cash: {
				visible: false,
			},
			modal_free: {
				visible: false,
			},

			states_list: [
				{
					message: 'En attente d\'une carte',
					status: 'pending',
					active: true
				},
				{
					message: 'En attente d\'un montant',
					status: 'pending',
					active: false
				},
				{
					message: 'En attente du paiment',
					status: 'pending',
					active: false
				},
				{
					message: 'Traitement de la demande',
					status: 'pending',
					active: false
				},
				{
					message: 'Capture du paiment',
					status: 'pending',
					active: false
				},
				{
					message: 'Ajout du crédit sur la carte',
					status: 'pending',
					active: false
				}
			]
		}
	},
	setup() {
		const apiStore = useApiStore();

		const {makeApiCall} = apiStore;
		const {spot_id} = storeToRefs(apiStore);


		return {makeApiCall, spot_id, TERMINAL_LOCATION_ID}
	},
	name: "manage_credit",
	mixins: [nfc_controller, stripe]
}
</script>


<style scoped>

</style>
