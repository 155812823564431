<template>
  <div>
    <div class="my-8 text-center">
      <span class="text-4xl font-bold my-4 mr-4">Liste de mes magasins</span>
    </div>

    <div class="container mx-auto px-5">
      <div class="flex flex-wrap items-center justify-center ">
        <template v-for="shop in shops" :key="shop">
          <store_button :shop="shop"></store_button>
<!--          <menu_button :url="'/store/' + shop.id" :title="shop.name" :img="shop.image"></menu_button>-->
        </template>
      </div>
    </div>



  </div>
</template>

<script>
// import menu_button from "@/components/prefabs/menu_button";
import store_button from "@/components/prefabs/store_button";

import {useShopStore} from "@/utils/store/shop_store";
import {storeToRefs} from "pinia/dist/pinia";

export default {
  name: "store_list_page",
  setup(){
    const shopStore = useShopStore();
    const {shops} = storeToRefs(shopStore);

    return{shops}
  },
  components:{
    store_button
    // menu_button,
  }
}
</script>

<style scoped>

</style>
