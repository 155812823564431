<template>
  <div class="p-2 w-full lg:w-1/3">
      <div class="border border-neutral-400 border-3 rounded-xl shadow-lg flex flex-col p-3 transition ease-in-out bg-white">
        <img :src="shop.image ?? require('/src/assets/images/default.png')" class="w-max-16 w-16 mx-auto" :alt="shop.name">
        <div class="text-2xl mx-auto mt-4">{{ shop.name }}</div>
        <div class="text-red-500 text-center mt-4" v-if="modified_product.length > 0">
          <div>Ce shops a des produits modifié</div>
          <span v-for="(product, index) in modified_product" :key="index">
            {{ product }}{{ index !== modified_product.length - 1 ? ', ' : '' }}
          </span>
        </div>
        <div class="flex flex-col gap-3 mt-5">
          <RouterLink :to="'/store/'+ shop.id +'/create-order'">
            <button class="btn-dark w-full"><i class="bi bi-cart-fill mr-3"></i> Crée une commande</button>
          </RouterLink>

          <RouterLink :to="'/store/' + shop.id">
            <button class="btn-outline-dark w-full"><i class="bi bi-shop mr-3"></i> Gestion du magasin</button>
          </RouterLink>

        </div>

      </div>
  </div>
</template>

<script>
export default {
  computed:{
    modified_product() {
      let modified_product = []
      this.shop.products.forEach((product) => {
        if(product?.modified === true) {
          modified_product.push(product.name)
        }
      })
      return modified_product
    },
  },
  props:{
    shop: {
      required: true
    },
  },
  name: "store_button"
}
</script>

<style scoped>

</style>
