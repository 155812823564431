import {loadStripeTerminal} from "@stripe/terminal-js";
import {TERMINAL_LOCATION_ID} from "@/utils/app_const";

export default {
	data() {
		return{
			terminal: null,
			terminal_is_connected: false,
		}
	},
	async created() {
		this.initTerminal().then((result) => {
			this.terminal = result;
			this.discoverReadersAndConnect();
		});
	},
	methods:{
		async initTerminal() {
			this.terminal_is_connected = false;
			const StripeTerminal = await loadStripeTerminal();
			return StripeTerminal.create({
				onFetchConnectionToken: this.fetchConnectionToken,
				onUnexpectedReaderDisconnect: this.unexpectedDisconnect,
			});
		},
		async fetchConnectionToken() {
			// Your backend should call /v1/terminal/connection_tokens and return the JSON response from Stripe
			const response = await this.makeApiCall('post', '/tpe/connection_tokens')

			const data = response.data;

			return data.secret;
		},

		unexpectedDisconnect() {
			alert("Le terminal de paiement a été déconnecté.");

		},
		async createPaimentIntent(amount, receipt_email, description = "") {
			const clientSecret = await this.makeApiCall('post', '/tpe/paymentIntent',
				{
					amount: amount,
					receipt_email: receipt_email,
					description: description
				});
			return await this.terminal.collectPaymentMethod(clientSecret.data);
		},

		async processPayment(collectPaymentMethod) {
			const result = await this.terminal.processPayment(collectPaymentMethod.paymentIntent);
			if (result.error) {
				alert(result.error.message);
			} else if (result.paymentIntent) {
				return result.paymentIntent.id;
			}
		},

		async capturePaymentIntent(payment_intent_id) {
			// Placeholder for notifying your backend to capture result.paymentIntent.id
			const result = await this.makeApiCall('post', '/tpe/paymentCapture', {payment_intent_id: payment_intent_id});
			return result.data;
		},
		async discoverReadersAndConnect() {
			const config = {simulated: false, location: TERMINAL_LOCATION_ID}
			const discoverResult = await this.terminal.discoverReaders(config);
			if (discoverResult.error) {
				console.log('Failed to discover: ', discoverResult.error);
			} else if (discoverResult.discoveredReaders.length === 0) {
				console.log('No available readers.');
			} else {
				console.log('Found readers: ', discoverResult.discoveredReaders)
				const selectedReader = discoverResult.discoveredReaders[0];

				await this.terminal.disconnectReader();
				const connectResult = await this.terminal.connectReader(selectedReader);
				if (connectResult.error) {
					console.log('Failed to connect:', connectResult.error);
					// alert('Impossible de se connecter au terminal de paiement.');
				} else {
					console.log('Connected to reader:', connectResult.reader.label);
					this.terminal_is_connected = true;
					return connectResult;
				}
			}
		},
	}
}
