<template>
  <div class="p-2 my-10 ">
    <div class="flex w-full justify-center items-center mt-5">
      <button class="btn-primary rounded-lg text-xl p-3  w-1/3" @click="scanUser">
        <span v-if="!reading">Démarrer le scan</span>
        <span v-else>Annuler le scan</span>
      </button>
    </div>
  </div>
</template>

<script>
import nfc_controller from "@/utils/nfc_controller";
import axios from "axios";
export default {
  mixins: [nfc_controller],
  methods: {
    async scanUser() {
      let response = await this.ToggleScan();

      if (response?.success) {


        console.log(response.serial_number)

       await axios.get('http://192.168.1.21:8000/scan.php?uid='+response.serial_number)

        return;
      }


      if (!response?.success && response?.message) {
        alert(response.message)
        return;
      }
      alert('Une erreur est survenue, veuillez réessayer');
    },
    data() {
      return {
        cards: null
      }
    },
    name: "fast_print",
  }
}
</script>

<style scoped>

</style>
