<template>

    <div class="bg-gradient-to-tr from-slate-200 to-slate-300/20 rounded-xl shadow-inner shadow-slate-400/50 p-5 h-full">
        <p class="text-3xl text-center  font-light">Statut du terminal</p>
        <hr class="border border-neutral-300 my-5">
        <p>
            Terminal :
            <span v-if="terminal_is_connected" class="text-green-600 font-bold"><i
                    class="bi bi-check-circle-fill mr-2"></i>Terminal OK</span>
            <span v-if="!terminal_is_connected" class="text-amber-600 font-bold">Recherche ...</span>
        </p>
        <p>
            Terminal location id : <span v-if="TERMINAL_LOCATION_ID || terminal_is_connected">{{ TERMINAL_LOCATION_ID ?? '-' }}</span>
            <span v-else class="font-bold text-red-600">PAS DE TERMINAL</span>
        </p>
        <p>
           Minimum paiment :    {{formatPrice(min_price)}}

        </p>
        <hr class="border border-neutral-300 my-5">
        <div class="flex justify-center items-center">
            <button class="text-6xl text-center text-blue-400" @click="refresh_page"><i
                    class="bi bi-arrow-clockwise"></i></button>
        </div>
    </div>

</template>

<script>
import {formatPrice, TERMINAL_LOCATION_ID} from "@/utils/app_const";

export default {
	name: "terminal_status",
    methods:{
        formatPrice,
        refresh_page() {
            window.location.reload();
        }
    },
    props:{
        terminal_is_connected:{
            default:false
        },
        min_price:{
            default:0.5
        }
    },
    setup() {
        return {
            TERMINAL_LOCATION_ID
        }
    },
}
</script>

<style scoped>

</style>
