<template>
    <div class="p-2 my-10 ">
        <h1 class="font-light text-5xl text-center">Information de la carte</h1>
        <h2 class="font-light text-xl text-center">{{ customer?.cardId }}</h2>

        <div class="bg-slate-600 p-5 mt-5 flex gap-4 rounded-xl" v-if="!original_customer">
            <p class="text-2xl text-white font-bold text-center mx-auto">Scanner une carte pour obtenir les
                informations</p>
        </div>

        <div class="bg-slate-600 p-5 mt-5 rounded-xl flex flex-col gap-4 items-center justify-center"
             v-if="original_customer">
            <div class="flex gap-4 w-full">
                <div class="w-96 flex flex-col justify-center items-center bg-white gap-10" id="print_qr"
                     :class="!customer.cardId ? 'hidden' : ''" @click="printUserRecap">

                    <div id="qrcode_user" class="h-28 w-28">
                    </div>


                    Solde : {{ getCreditToEuro(customer?.credit) }}
                </div>
                <div class="text-white w-full flex-col">
                    <div class="flex gap-5">
                        <div class="flex flex-col gap-2 text-3xl w-1/2 p-2">
                            <div>
                                <p>Prénom :</p>
                                <input v-model="customer.firstname" class="input-primary">
                            </div>

                            <div>
                                <p>Nom :</p>
                                <input v-model="customer.lastname" class="input-primary">
                            </div>
                        </div>

                        <div class="flex flex-col gap-2 text-3xl w-1/2 p-2">
                            <div>
                                <p>Email :</p>
                                <input type="email" v-model="customer.email" class="input-primary">
                            </div>
                            <div>
                                <p>Tel. :</p>
                                <input v-model="customer.phone" class="input-primary">
                            </div>
                        </div>

                        <div class="flex flex-col justify-around items-center text-3xl w-1/3 p-2">
                            <div @click="toggleCustomerActive()">
                                <div v-if="customer?.active">
                                    <p class="text-green-600 bg-emerald-200 rounded-xl p-5">ACTIF</p>
                                </div>
                                <div v-else>
                                    <p class="text-red-600 bg-rose-200 rounded-xl p-5">NON ACTIF</p>
                                </div>
                            </div>
                            <div class="flex flex-col justify-center  mt-5">
                                <p class="font-bold text-2xl">Solde : {{ getCreditToEuro(customer?.credit) }}</p>
                                <button class="bg-red-500 transition hover:bg-red-400 font-bold text-white py-2 px-4 w2/3 text-sm rounded-lg mx-auto mt-5"
                                        @click="modal_customer_money_reset.visible = true">
                                    Passer le solde à 0
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="w-1/3 mx-auto mt-5" v-if="is_edited">
                <button class="p-5 w-full bg-sky-500 text-white text-2xl rounded-xl transition hover:bg-sky-400"
                        @click="saveCustomer">
                    Sauvegarder les changements apportés.
                </button>
            </div>

        </div>

        <div class="flex w-full justify-center items-center mt-5">
            <button class="btn-primary rounded-lg text-xl p-3  w-1/3" @click="scanUser">
                <span v-if="!reading && !original_customer">Démarrer le scan</span>
                <span v-else-if="!reading">Scanner une autre carte</span>
                <span v-else>Annuler le scan</span>
            </button>
        </div>

        <Dialog v-model:visible="modal_customer_money_reset.visible" modal :style="{ width: '50vw' }"
                :breakpoints="{ '960px': '75vw', '641px': '100vw' }" :dismissable-mask="true">
            <template #header>
                <div class="w-full">
                    <h1 class="text-2xl font-bold">Attention !</h1>
                </div>
            </template>
            <h2 class="text-xl text-drift text-center mb-5">Vous allez passez le solde de l'utilisateur a 0 € . cette
                action n'est pas reversible. voulez vous continuez ?</h2>
            <div class="flex">
                <button class="btn-primary mx-auto w-full lg:w-1/3" @click="modal_customer_money_reset.visible = false">
                    <i class="bi bi-x-octagon-fill mr-3"></i>Non
                </button>
                <button class="btn-success mx-auto w-full lg:w-1/3" @click="resetCustomerMoney">
                    Oui
                </button>
            </div>
        </Dialog>

    </div>
</template>

<script>
import nfc_controller from "@/utils/nfc_controller";
import {BtPrint, DEFAULT_URL_QR, getCreditToEuro} from "@/utils/app_const";
import {useApiStore} from "@/utils/store/api_store";
import Dialog from 'primevue/dialog';
import {makeQrCode} from "@/utils/qr";

export default {
	methods: {
		BtPrint,
		makeQrCode,
		getCreditToEuro,
		printUserRecap() {
			if (this.customer) {
				this.BtPrint('print_qr');
			}
		},
		async scanUser() {
			let response = await this.ToggleScanUser(false);

			if (response?.success && response?.customer) {

				this.customer = response.customer;
				this.original_customer = JSON.parse(JSON.stringify(response.customer));


				console.log(DEFAULT_URL_QR + response.customer.cardId)


				setTimeout(() => {
					this.makeQrCode("qrcode_user", DEFAULT_URL_QR + response.customer.cardId, 110);
				}, 100);


				return;
			}


			if (!response?.success && response?.message) {
				alert(response.message)
				return;
			}
			alert('Une erreur est survenue, veuillez réessayer');
		},
		async saveCustomer() {
			try {
				await this.makeApiCall('post', '/customers/update', {customer: this.customer})
				this.original_customer = JSON.parse(JSON.stringify(this.customer));
				alert('Le client a bien été mis à jour');
			} catch (e) {
				console.log(e);
				alert('Une erreur est survenue, veuillez réessayer');
			}
		},
		print_qr() {

		},
		toggleCustomerActive() {
			this.customer.active = !this.customer.active;
		},
		async resetCustomerMoney() {

			this.modal_customer_money_reset.visible = false;

			await this.makeApiCall('post', '/customers/' + this.customer.id + '/reset-credit');

			this.customer.credit = 0;
			this.original_customer.credit = 0;
		}
	},
	data() {
		return {
			customer: { // des field par default pour v-model
				cardId: null,
				firstname: null,
				lastname: null,
				email: null,
				active: false,
			},
			modal_customer_money_reset: {
				visible: false,
			},
			original_customer: null,
		}
	},
	computed: {
		is_edited() {
			return JSON.stringify(this.customer) !== JSON.stringify(this.original_customer);
		}
	},
	setup() {
		const apiStore = useApiStore();
		const {makeApiCall} = apiStore;
		return {
			makeApiCall
		}
	},
	name: "read-user",
	components: {
		Dialog
	},
	mixins: [nfc_controller],
}
</script>

<style scoped>

</style>
