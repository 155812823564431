<template>
    <div class="bg-gradient-to-tr from-slate-50 to-slate-200">
        <div class="flex flex-col bg-gradient-to-t min-h-screen">
            <header_layout></header_layout>

            <RouterView class="h-full flex-grow"></RouterView>

        </div>
    </div>
</template>

<script>
import routerView from "vue-router";
import header_layout from "./components/Layout/header.vue";

export default {
	name: 'App',
	components: {
		routerView,
		header_layout
	}
}
</script>

<style>
</style>
