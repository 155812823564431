<template>
  <div class="my-auto">

    <div class="container mx-auto p-5 flex flex-col ">

        <button class="btn-primary mx-auto text-4xl p-5" @click="ToggleScan">
          <span v-if="!reading">Démarrer le scan</span>
          <span v-else>Annuler le scan</span>
        </button>

        <h1 class="text-drift text-6xl text-center my-8 font-medium" v-if="reading">En attente d'une puce NFC ...</h1>

        <div class="text-center text-xl my-8" v-if="scan_data">
          <p class="text-center text-2xl my-2">Données sur le tag :</p>
          {{ scan_data }}
        </div>




    </div>

  </div>
</template>

<script>
import nfc_controller from "@/utils/nfc_controller";

export default {
  methods: {
    async ToggleScan() {

      this.scan_data = null;
      if(!this.reading) {
        const response = await this.scanTag();
        if (response.success) {
          this.scan_data = response.data;
        }
      }else{
        this.stopScan();
      }

    },
  },
  data() {
    return {
      scan_data: null,
    }
  },
  name: "nfc_reader_page",
  mixins: [nfc_controller],

}
</script>

<style scoped>

</style>
