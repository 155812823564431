<template>
    <div class="bg-white  w-1/2 mx-auto">
        <div id="ticket-add-credit-print" class="bg-white p-2">
            <p class="text-3xl text-center">Driftcup</p>
            <p class="text-2xl text-center">Carte prépayée #{{ order?.id ?? '' }}</p>
            <p class="text-lg text-center">
                <template v-if="pay_method === 'cb'">
                    Paiement par CB
                </template>
                <template v-else-if="pay_method === 'cash'">
                    Paiement en espèce
                </template>
                <template v-else-if="pay_method === 'free'">
                   Offert
                </template>

            </p>

            <hr>
            <div class="flex flex-col justify-center items-center text-sm py-2 ">
                <p>{{ customer?.firstname ?? '' }}</p>
                <p>{{ customer?.lastname ?? '' }}</p>
            </div>

            <div class="grid grid-cols-2 mt-5" v-if="order">
                <p>Solde de départ :</p>
                <p class="text-end">{{ getCreditToEuro(order.credit_before ?? 0) }}</p>
                <p>Montant payé :</p>
                <p class="text-end">{{ formatPrice(order?.customer_paid ?? 0) }}</p>

                <p class="mt-5">Solde après paiement :</p>
                <p class="mt-5 text-end">{{ getCreditToEuro(order.credit_after ?? 0) }}</p>
            </div>


            <div class="flex">
                <div class="my-5 mx-auto w-1/3">
                    <div id="qrcode-ticket">

                    </div>
                </div>
            </div>


        </div>

    </div>
</template>

<script>
import {getCreditToEuro,formatPrice} from "@/utils/app_const";
export default {
    props:{
        order:{
            type:Object,
        },
        customer:{
            type:Object,
        },
        pay_method:{
            type:String,
        }
    },
    setup(){
        return{
            getCreditToEuro,
            formatPrice
        }
    },
	name: "ticket_bl",

}
</script>

<style scoped>

</style>
