<template>
    <div>
        <div v-if="!print">

            <div v-if="!customer" class="flex flex-col">
                <button class="btn-primary mx-auto text-4xl p-5 mt-10 max-lg:w-1/2 max-lg:text-xl" @click="scanUser">
                    <span v-if="!reading">Démarrer le scan</span>
                    <span v-else>Annuler le scan</span>
                </button>

                <h1 class="text-drift text-6xl text-center my-8 font-medium max-lg:w-1/2 max-lg:text-xl mx-auto"
                    v-if="reading">
                    En attente d'une carte driftcup ...</h1>

              <img src="/driftcup.png" class="w-1/2 mx-auto">

              <img>


            </div>

            <div v-else>

                <div v-if="shop">

                    <div class="w-full bg-slate-700 text-white flex py-2 px-3 text-center items-center text-lg">
                        <i class="bi bi-person-circle"></i>
                        <div class="flex-grow text-left px-3">
                            {{ customer?.firstname ?? 'Guest' }} {{ customer?.lastname ?? '' }}
                        </div>
                        <div class="flex-grow text-end">
                            {{ getCreditToEuro(customer?.credit ?? 0) }}
                            <template v-if="(customer?.credit ?? 0) !== credit_left"><i
                                    class="bi bi-arrow-right mx-2"></i>
                                <span class="font-bold max-lg:align-baseline"
                                      :class="{'text-amber-500':!customer_banckroupt,'text-drift':customer_banckroupt}">
              {{ getCreditToEuro(credit_left) }}
            </span>
                            </template>
                        </div>
                    </div>

                    <div class="container mx-auto p-1 text-xl max-lg:text-lg">

                        <div class="flex flex-wrap gap-10 justify-center bg-gradient-to-tr to-slate-200 from-slate-300 p-2 rounded-xl max-lg:gap-1">

                            <div v-for="product in shop.products" :key="product"
                                 class="w-48 h-48 shadow-inner shadow-neutral-300/50 rounded-2xl p-1 flex flex-col bg-white cursor-pointer relative

            max-lg:w-32 max-lg:h-32"
                                 @click="addProduct(product)">
                                <div class="absolute top-0 left-1">
                                    <i class="bi bi-plus-lg  "></i>
                                </div>

                                <img :src="product.image" :alt="product.name" class="h-1/2 mx-auto">
                                <p class="text-center text-sm my-auto ">{{ product.name }}</p>
                                <p class="text-center mt-auto">{{ getCreditToEuro(product.price) }} </p>
                            </div>

                        </div>

                        <hr class="my-2 border-t-2 border-t-slate-600">


                        <div class="flex flex-wrap justify-center gap-2 bg-gradient-to-tr to-sky-300/25 from-sky-100/25 shadow-inner shadow-emerald-300/30 rounded-xl p-1"
                             v-if="order.length > 0">

                            <div v-for="product in order" :key="product"
                                 class="w-48 h-48 shadow-inner shadow-sky-600/20 rounded-2xl p-2 flex flex-col cursor-pointer bg-white/70
            max-lg:w-32 max-lg:h-32 relative"
                                 @click="removeProduct(product)">
                                <div class="absolute top-0 left-1">
                                    <i class="bi bi-dash-lg"></i>
                                </div>
                                <img :src="product.image" :alt="product.name" class="h-1/2 mx-auto">
                                <p class="text-center text-sm my-auto ">{{ product.name }}</p>

                                <p class="text-center mt-auto font-bold text-drift text-sm">
                                    <span class="text-center lg:text-lg mt-auto text-sm text-black"> x{{
                                        product.quantity
                                        }} </span>
                                    {{ getCreditToEuro(product.price * product.quantity) }}

                                </p>
                            </div>

                            <div id="qrcode">
                            </div>

                        </div>

                    </div>

                    <div class="text-center text-xl mb-20 max-lg:px-5" v-if="total > 0">
                        <p class="text-black">Credit client : {{ getCreditToEuro(customer.credit) }}</p>
                        <p class="text-green-600 font-bold">Total : {{ getCreditToEuro(total) }}</p>
                        <p :class="{'text-drift':credit_left<0,'text-green-500':credit_left>=0}" class="font-bold">
                            Credit restant = {{ getCreditToEuro(credit_left) }} </p>

                        <button class="btn-success mt-4" :disabled="customer_banckroupt" @click="finishOrder">Finalisé
                            la
                            commande
                        </button>
                        <p class="text-drift font-extrabold text-center text-xl" v-if="customer_banckroupt">Pas assez de
                            credit
                            !</p>

                    </div>

                </div>


            </div>


        </div>

        <bl-ticket :order="order" :shop="shop" :customer="customer" :total="total" :order_finished="order_finished"
                   v-if="print"></bl-ticket>

    </div>

</template>

<script>
import {useApiStore} from "@/utils/store/api_store";
import nfc_controller from "@/utils/nfc_controller";
import {useShopStore} from "@/utils/store/shop_store";
import {storeToRefs} from "pinia/dist/pinia";
import BlTicket from "@/components/store/bl-ticket.vue";
import {getCreditToEuro,BtPrint} from "@/utils/app_const";

export default {
	components: {BlTicket},
	methods: {
      BtPrint,
		async scanUser() {
			let response = await this.ToggleScanUser();

			if (response?.success && response?.customer) {
				this.customer = response.customer;
				return;
			}
			if (!response?.success && response?.message) {
				alert(response.message)
				return;
			}
			alert('Une erreur est survenue, veuillez réessayer');
		},
		async finishOrder() {
			this.order_finished = false;
			const response = await this.makeApiCall("post", "/orders/register-new", {
				customer_id: this.customer.id,
				shop_id: this.shop.id,
				spot_id: this.spot_id,
				products: this.order.map(e => {
					return {
						id: e.id,
						quantity: e.quantity,
						price: e.price,
					}
				})
			});
			if (response.data.success) {
				this.print = true;
				setTimeout(async () => { // Laisse le temps à la div de s'afficher avant de l'imprimer
					await this.BtPrint('element-to-print');
					this.order_finished = true;
          alert('Commande finalisé !');
				}, 1000);

			} else {
				alert(response.data.message);
				this.goBack();
			}


		},
		addProduct(product) {
			product = {...product};
			//if a product with same id exist in order, increment quantity else push new
			let index = this.order.findIndex(e => e.id === product.id);
			if (index !== -1) {
				this.order[index].quantity++;
			} else {
				product.quantity = 1;
				this.order.push(product);
			}
		},
		removeProduct(product) {
			let index = this.order.findIndex(e => e.id === product.id);
			if (index !== -1) {
				if (this.order[index].quantity > 1) {
					this.order[index].quantity--;
				} else {
					this.order.splice(index, 1);
				}
			}
		},
		goBack() {
			this.$router.go(-1);
		}
	},
	computed: {
		total() {
			let total = 0;
			this.order.forEach(e => {
				total += e.price * e.quantity;
			});
			return total;
		},
		credit_left() {
			return (this.customer?.credit ?? 0) - this.total;
		},
		customer_banckroupt() {
			return this.credit_left < 0;
		}
	},
	created() {
		this.shop = this.getCurrentShop(this.shop_id);
	},
	data() {
		return {
			shop_id: this.$route.params.shop_id,
			shop: null,
			customer: null,
			print: false,
			order_finished: false,
			order: [],
		}
	},
	setup() {
		const apiStore = useApiStore();
		const shopStore = useShopStore()

		const {makeApiCall} = apiStore;
		const {getCurrentShop} = shopStore;
		const {spot_id} = storeToRefs(apiStore);


		return {makeApiCall, shopStore, getCurrentShop, spot_id, getCreditToEuro}
	},
	mixins: [nfc_controller],
	name: "create_order"
}
</script>

<style scoped>

</style>
