import { defineStore } from 'pinia'
import {useApiStore} from "@/utils/store/api_store";


// You can name the return value of `defineStore()` anything you want,
// but it's best to use the name of the store and surround it with `use`
// and `Store` (e.g. `useUserStore`, `useCartStore`, `useProductStore`)
// the first argument is a unique id of the store across your application



export const useShopStore = defineStore('shopStore', {
	state: () => {
		return {
			shops: {},
		}
	},
	actions: {
		async fetchShop(){
			const apiStore = useApiStore()
			const {makeApiCall,spot_id} = apiStore;

			try{
				const response = await makeApiCall('post', '/get-stores',{'spot_id': spot_id}, {});

				this.shops = response.data.stores;

				return {
					success: true,
					message: 'Shop fetched successfully'
				}
			}catch (e){
				console.log(e);
				return {
					success: false,
					message: 'Error while fetching shop'
				}
			}
		},
		getCurrentShop(shop_id){
			const id = parseInt(shop_id);
			return this.shops.find(shop => shop.id === id);
		}
	},
	persist: true,
});
