<template>
  <div>
    <div class="my-8 text-center">
      <span class="text-4xl font-bold my-4 mr-4">{{ APP_NAME }}</span>
      <span class="text-2xl font-normal italic">{{ APP_VERSION }}</span>
    </div>

    <div class="container mx-auto px-5">
      <div class="flex flex-wrap items-center justify-center lg:w-1/2 lg:mx-auto">
        <menu_button url="store" title="Magasin" icon="bi bi-shop-window"></menu_button>
      </div>
      <hr class="border border-neutral-300 my-10">
      <div class="grid grid-cols-1  items-center justify-center lg:">
        <menu_button url="/admin" title="Configuration" icon="bi bi-gear-wide-connected" class="w-full lg:w-1/3 mx-auto"></menu_button>
      </div>


    </div>

  </div>

</template>

<script>
import {APP_NAME, APP_VERSION} from '/src/utils/app_const';
import menu_button from "@/components/prefabs/menu_button";


export default {

  name: "index_page",
  components: {
    menu_button,
  },
  setup() {
    return {APP_NAME, APP_VERSION}
  }
}
</script>

<style scoped>

</style>
