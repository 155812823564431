export default {
	methods: {
		async ToggleScan() {
			if (!this.reading) {
				const response = await this.scanTag();
				if (response.success) {
					return response;
				}
			} else {
				this.stopScan();
				return null
			}
		},

		async ToggleScanUser(active = true) {
			let scan_data = await this.ToggleScan();
			if(scan_data === null || scan_data === undefined) {
				return null;
			}
			const response_customer = await this.makeApiCall("post", "/customers/get-with-card",{
				card_data: scan_data,
				active: active
			});
			return response_customer.data;
		},

		async writeTag(data,recordType = "text" ) {

			if (!('NDEFReader' in window)) {
				return;
			}

			this.writing = !this.writing;
			if (!this.writing) {
				return;
			}

			try {
				this.abortController = new AbortController();
				const ndefReader = new window.NDEFReader();
				await ndefReader.write({
					records: [{recordType: recordType, data: data}]
				});
				this.writing = false;
				return true;
			} catch (error) {
				console.log(error);
				this.writing = false;
				return false;
			}
		},

		async scanTag() {
			if (!('NDEFReader' in window)) {
				return;
			}

			this.reading = !this.reading;
			if (!this.reading) {
				return;
			}
			this.lastReadedTag = null;

			const ndef = new window.NDEFReader();

			return new Promise((resolve, reject) => {
				try {
					this.abortController = new AbortController();
					ndef.scan({signal: this.abortController.signal});

					ndef.onreading = event => {
						const decoder = new TextDecoder();
						let data = [];
						console.log(event)
						for (const record of event.message.records) {
							console.log("Record type:  " + record.recordType);
							console.log("MIME type:    " + record.mediaType);
							if(record.data instanceof ArrayBuffer) {
								data.push(decoder.decode(record.data))
								console.log("=== data ===\n" + decoder.decode(record.data));
							}

						}
						this.stopScan()

						const return_data = {
							success: true,
							serial_number: event.serialNumber,
							data: data,
							error: null,
						}

						resolve(return_data);

					}


				} catch (error) {
					console.log(error);
					const return_data = {
						success: false,
						data: null,
						error: error,
					}
					reject(return_data);
				}
			});


		},
		stopScan() {
			this.abortController.abort();
			this.reading = false;
			this.writing = false;

			return true;
		},
	},
	data() {
		return {
			reading: false,
			writing: false,
			abortController: null,
		}
	},
}
