import { defineStore } from 'pinia'
import axios from "axios";
import {url_api} from "/src/utils/app_const";

// You can name the return value of `defineStore()` anything you want,
// but it's best to use the name of the store and surround it with `use`
// and `Store` (e.g. `useUserStore`, `useCartStore`, `useProductStore`)
// the first argument is a unique id of the store across your application
export const useApiStore = defineStore('apiStore', {
	actions: {
		async makeApiCall(methods = 'get', url= '/test', data = {}, headers = {}) {

			const header_bearer_token = {
				'Authorization': 'Bearer ' + this.bearer_token_api
			}
			headers = {...headers, ...header_bearer_token};

			return axios({
				method: methods,
				url: this.url_base + url_api + url,
				data: data,
				headers: headers,
			});
		},
		async testApiUrl(server_ip ,bearer_token_api,url = '/test',){
			return axios.get(server_ip +'/api'+ url,{
				headers: {
					'Authorization': 'Bearer ' + bearer_token_api
				}
			});
		}
	},
	state: () => {
		return {
			spot_id: 'POS-',
			url_base: 'http://192.168.1.38:8000' , //'http://127.0.0.1:8000'
			connected: false,
			bearer_token_api: '',
		}
	},
	persist: true,
});
