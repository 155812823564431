<template>
    <div class=" mx-auto py-10 px-5">
        <p class="text-5xl font-light  text-center">Initialiser une carte</p>

        <div class="flex flex-col gap-2 justify-center items-center" v-if="!reading && !init_finish">
            <div class="text-2xl mt-5 font-light">
                <input type="checkbox" id="checkbox_volunteer" v-model="volunteer" :value="true">
                <label for="checkbox_volunteer">
                    Carte pour Bénévole
                </label>
            </div>


            <div class="grid-cols-2 grid gap-10">
                <div class="flex flex-col mx-auto w-full  my-10 gap-5">
                    <template v-for="credit in init_price_list" :key="credit">
                        <button class="text-white shadow-inner p-5 w-full text-6xl rounded-xl"
                                :class="getColorBtn(credit)"
                                @click="create_user(credit)">
                            {{ credit * 0.01 }} €
                        </button>
                    </template>
                </div>
                <div class="flex flex-col gap-2 my-auto">
                    <p class="text-red-600 text-2xl  text-center">Montant personalisé</p>
                    <input type="number" v-model="custom_credit" class="p-2 text-center border border-red-600 text-2xl"
                           max="100">
                    <button class="text-white shadow-inner p-5 w-full text-6xl rounded-xl bg-red-800 disabled:opacity-50"
                            :disabled="custom_credit<= 0.5 || custom_credit > 100"
                            @click="create_user(custom_credit * 100)">
                        {{ custom_credit }} €
                    </button>
                    <p class="text-red-600 text-xl  text-center mt-10">Nombre de repas</p>
                    <div class="flex flex-wrap gap-5 items-center justify-center">
                        <button class="text-white shadow-inner p-3 w-1/3 text-lg rounded-xl bg-red-600 disabled:opacity-50" v-for="num in 7" :key="num"
                                @click="create_user(repas_price * num)">
                            {{num}} repas ( {{ (repas_price * 0.01) * num }} € )
                        </button>
                    </div>
                </div>
            </div>

        </div>


        <div class="flex flex-col gap-5 justify-center items-center lg:w-1/2 mx-auto mt-10"
             v-if="reading && !init_finish">
            <button class="text-white shadow-inner p-5 w-full text-6xl rounded-xl" :class="getColorBtn(credit_add)"
                    @click="create_user(credit_add)">
                Annuler
            </button>

            <h1 class="text-center text-5xl">
                <span :class="getColorText(credit_add)">Création d'une carte de {{ credit_add * 0.01 }} €</span>
                <span :class="getColorText(credit_add)" class="mt-10">...</span>
            </h1>
        </div>

        <div v-if="init_finish" class="flex flex-col justify-center items-center gap-10 my-10"
             :class="{'text-amber-600':status_finish === 'pending','text-red-600':status_finish === 'error','text-green-600':status_finish === 'success'}">
            <i class="bi bi-check-circle-fill text-9xl text-green-600" v-if="status_finish === 'success'"></i>
            <i class="bi bi-exclamation-circle-fill  text-red-600 text-9xl" v-if="status_finish === 'error'"></i>
            <i class="bi bi-hourglass text-9xl text-amber-600" v-if="status_finish === 'pending'"></i>

            <p class="text-4xl">{{ this.message_finish }}</p>

            <button class="text-white shadow-inner p-5 text-6xl rounded-xl w-1/2 transition ease-in-out disabled:opacity-25" :class="getColorBtn(credit_add)"
                    @click="reset()" :disabled="!buttonReset">Ok
            </button>


            <div id="print_create_user" class="text-black bg-white p-4 w-1/3">
                <p class="text-5xl text-center">DriftCup</p>

                <div class="flex mt-7">
                    <div class="my-5 mx-auto w-1/3">
                        <div id="ticket-create-qr" class="text-black">

                        </div>
                        <p class="text-xs">{{ customer?.cardId ?? '' }}</p>
                    </div>
                </div>

                <p class="text-center text-2xl text-black tracking-wide">Vous pouvez utiliser votre carte dès maintenant !
                    Scanner ce QR code pour connaître votre solde et renseigner vos informations en cas de
                    perte.</p>

                <p class="mt-5 text-center text-2xl">
                    Votre solde : {{ getCreditToEuro(customer_credit ?? 0) }}
                </p>
            </div>


        </div>

    </div>
</template>

<script>
import nfc_controller from "@/utils/nfc_controller";
import {useApiStore} from "@/utils/store/api_store";
import {makeQrCode} from "@/utils/qr";
import {BtPrint, DEFAULT_URL_QR, getCreditToEuro,REPAS_PRICE} from "../../utils/app_const";

export default {
	methods: {
		getCreditToEuro,
		async create_user(credit = 0) {
			this.credit_add = credit;
			let card_data = await this.ToggleScan();
			if (!card_data || !card_data.success) {
				this.credit_add = 0;
				return false;
			}

			// try {
			// 	this.write_url = await this.writeTag(DEFAULT_URL_QR + card_data.serial_number, 'url');
			// } catch (e) {
			// 	this.write_url = false
			// }

			this.init_finish = true;

			let response;
			try {

				let roles = [];

				if (this.volunteer) {
					roles.push('VOLUNTEER');
				}

				response = await this.makeApiCall('post', '/customers/create', {
						card_data: card_data,
						credit: credit,
						roles: roles

					}
				)
			} catch (e) {
				this.message_finish = 'Une erreur est survenue lors de la création de la carte';
				this.status_finish = 'error';
				return false;
			}


			if (response.data.success === true) {
				this.customer_credit = response.data.customer_credit ?? 0;

				setTimeout(() => {
					makeQrCode('ticket-create-qr', DEFAULT_URL_QR + card_data.serial_number, 150);
				}, 500);
				setTimeout(() => {
					this.BtPrint();
				}, 500);
			}




			this.message_finish = response.data.message;
			this.status_finish = response.data.success ? 'success' : 'error';

        setTimeout(()=> {
            this.buttonReset = true
        },2000)

		},
		getColorBtn(credit) {
			switch (credit) {
				case 0:
					return 'shadow-neutral-300 bg-neutral-500';
				case 500:
					return 'shadow-emerald-300 bg-emerald-500';
				case 1000:
					return 'shadow-sky-300 bg-sky-500';
				case 2500:
					return 'shadow-purple-300 bg-purple-500';
				case 5000:
					return 'shadow-amber-300  bg-amber-500';
				default:
					return 'shadow-red-300 bg-red-600';
			}
		},
		getColorText(credit) {
			switch (credit) {
				case 0:
					return 'text-neutral-600';
				case 500:
					return 'text-emerald-600';
				case 1000:
					return 'text-sky-600';
				case 2500:
					return 'text-purple-600';
				case 5000:
					return 'text-amber-600';
				default:
					return 'text-red-600';
			}
		},
		reset() {
			this.init_finish = false;
			this.message_finish = '';
			this.status_finish = 'pending';
			this.credit_add = 0;
			this.customer_credit = null;
			this.write_url = false;
			this.volunteer = false
        this.buttonReset = false;
		},
		BtPrint
	},
  created() {
    this.repas_price = REPAS_PRICE ?? 850
  },
  data() {
		return {
			credit_add: 0,
			init_price_list: [0, 500, 1000, 2500, 5000],
			init_finish: false,
			message_finish: '',
			status_finish: 'pending',
			customer_credit: 0,
			write_url: false,
			custom_credit: 0,
			volunteer: false,
			buttonReset: false,
        repas_price: 850


		}
	},
	setup() {
		const api_store = useApiStore();

		let {makeApiCall} = api_store;
		return {
			makeApiCall
		}
	},
	mixins: [nfc_controller],
	name: "create_user"
}
</script>

<style scoped>

</style>
