<template>
  <div>

    <div class="container mx-auto p-5">
      <p class="text-drift text-center font-bold">* Les changements seront effectifs uniquement sur votre dispositif</p>

      <div class="flex flex-wrap justify-center">
        <div class="w-1/2 lg:w-1/4 p-4 select-none" v-for="(product,index) in config_shop.products" :key="product">
          <div class="border-neutral-400 border border-1 rounded-lg shadow-lg flex flex-col p-2 gap-2"
               :class="{'ring-drift ring-1 bg-white':isProductDifferent(index)}">
            <img :alt="product.name" :src="product.image" class="h-16 w-16 lg:h-32 lg:w-32 mx-auto grayscale-[50%]"
                 :class="{'grayscale-[0]':isProductDifferent(index)}">

            <div class="flex flex-col">
              <p class="text-center">Nom du produit</p>
              <input class="input-primary text-center mb-3" v-model="product.name">
            </div>

            <div class="flex flex-col mb-3">
              <p class="text-center">Prix du produit</p>
              <input type="number" min="0" class="input-primary text-center" v-model="product.price">
            </div>

            <button class="btn-primary w-full" :disabled="!isProductDifferent(index)" @click="saveProduct(index)">
              Sauvegarder les modification
            </button>

            <button class="btn-outline-primary w-full" v-if="product?.modified" @click="resetProduct(index)">
              Réinitialiser les modifications
            </button>

          </div>
        </div>
      </div>


    </div>


  </div>
</template>

<script>
import {useShopStore} from "@/utils/store/shop_store";
import {useApiStore} from "@/utils/store/api_store";
import {storeToRefs} from "pinia/dist/pinia";

export default {

  methods: {
    saveProduct(product_index) {
      this.config_shop.products[product_index].modified = true;
      this.shop.products[product_index] = {...this.config_shop.products[product_index]};
    },
    async resetProduct(index){
      let product_id =  this.config_shop.products[index].id;
      const response = await this.makeApiCall('get', '/product/get_product/' + product_id);

      if(response.status === 200){
        console.log(JSON.parse(response.data));
       this.config_shop.products[index] = JSON.parse(response.data);
       this.shop.products[index] = {...this.config_shop.products[index]};

      }

    },
    isProductDifferent(product_index) {
      const modified_product = this.config_shop.products[product_index];

      const original_product = this.shop.products[product_index];

      return JSON.stringify(modified_product) !== JSON.stringify(original_product);

    },

  },
  created() {
    this.shop = this.getCurrentShop(this.shop_id);
    this.config_shop = JSON.parse(JSON.stringify(this.shop));
  },
  data() {
    return {
      shop_id: this.$route.params.shop_id,
      shop: null,
      config_shop: null,
    }
  },

  setup() {
    const shopStore = useShopStore();
    const apiStore = useApiStore();

    const {spot_id} = storeToRefs(apiStore);
    const {makeApiCall} = apiStore;
    const {getCurrentShop} = shopStore;

    return {getCurrentShop, makeApiCall, spot_id}
  },
  name: "manage_product_page",
}
</script>

<style scoped>

</style>
