<template>
    <div class="flex flex-col">


        <div class="mx-auto w-full lg:w-1/2 p-5" v-if="!is_good_password">
            <h1 class="text-center font-bold text-2xl mb-4">Espace protégé</h1>

            <p class="text-lg">Mot de passe</p>
            <div class="flex">
                <input type="password" class="input-primary rounded-r-none  w-full flex-grow" v-model="input_password"
                       v-on:keyup.enter="setPasswd">
                <button class="btn btn-primary rounded-l-none flew-shrink px-2" @click="setPasswd">Check</button>
            </div>
        </div>

        <div class="">

        </div>


        <div class="lg:w-2/3 gap-10 w-full mx-auto" v-if="is_good_password">

            <button class="btn-primary rounded-lg text-sm p-1 px-2 mt-4 mb-3 ml-2 w-fit " @click="logout()"
                    v-if="is_good_password">Fermé la
                session
            </button>

            <div class="flex">
                <div class="p-2 flex-grow h-fit">
                    <div class="flex flex-col p-2
                bg-gradient-to-tr from-neutral-100 to-neutral-200 shadow-inner shadow-neutral-300 rounded-lg">
                        <menu_button url="/admin/config" title="Configuration"
                                     icon="bi bi-gear-wide-connected"></menu_button>
                        <menu_button url="/admin/tpe" title="Configuration TPE"
                                     icon="bi bi-calculator-fill"></menu_button>
                      <menu_button url="/admin/utils/fastprint" title="Fastprint"
                                   icon="bi bi-qr-code"></menu_button>
                    </div>
                </div>
                <div class="p-2 flex-grow h-fit">
                    <div class="flex flex-col bg-gradient-to-tr from-sky-100 to-sky-200 shadow-inner shadow-sky-300 rounded-lg">
                        <menu_button url="/admin/utils/manage-credit" title="Ajouter du solde"
                                     icon="bi bi-coin"></menu_button>
                        <menu_button url="/admin/read-card" title="Lire une carte"
                                     icon="bi bi-credit-card-2-front-fill"></menu_button>
                        <menu_button url="/admin/utils/create-user" title="Initialiser une carte" icon="bi bi-person-fill-add"
                                  ></menu_button>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import menu_button from "@/components/prefabs/menu_button.vue";
import {good_password} from "@/utils/app_const";

export default {
	name: "index-admin",
	methods: {
		setPasswd() {
			localStorage.setItem('drftsh_admin_ok', this.input_password);
			this.input_password = '';
			this.checkPasswd();
		},
		checkPasswd() {
			this.is_good_password = localStorage.getItem('drftsh_admin_ok') === good_password;
		},
		logout() {
			localStorage.removeItem('drftsh_admin_ok');
			this.checkPasswd();
		}
	},
	data() {
		return {
			input_password: '',
			is_good_password: false
		}
	},
	created() {
		this.checkPasswd();
	},
	components: {menu_button}
}
</script>


<style scoped>

</style>
