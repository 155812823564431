<template>
    <div class="flex flex-col">


        <button class="btn-primary text-center w-1/2 mx-auto py-5 text-lg mb-8 mt-10 disabled:opacity-25" @click="goBack()" v-if="order_finished" :disabled="!enabled_button">Retour</button>
        <div class="py-5" v-else><img :src="require('/src/assets/images/spinner.svg')" alt="spinner" class="w-24 mx-auto"></div>

        <div id="element-to-print" class="lg:w-[120mm] w-full bg-white pb-10 mx-auto">
            <p class="lg:text-xl text-3xl text-center pb-4">{{ shop.name }}</p>


            <div class="grid grid-cols-2 lg:text-lg text-2xl">
                <template v-for="product in order" :key="product">
                    <div>
                        {{ product.name }}
                    </div>
                    <div class="text-right">
                        {{ product.quantity }} X {{ getCreditToEuro(product.price * product.quantity) }}
                    </div>
                </template>
            </div>
            <hr class="border-t-neutral-800 border-2 my-4">
            <div class="grid grid-cols-2 lg:text-lg text-xl">
                <div>
                    Solde initial :
                </div>
                <div class="text-right">
                    {{ getCreditToEuro(customer.credit) }}
                </div>
                <div>
                    Total :
                </div>
                <div class="text-right">
                    {{ getCreditToEuro(total) }}
                </div>

                <div>
                    Solde restant :
                </div>
                <div  class="text-right">
                    {{ getCreditToEuro(customer.credit - total) }}
                </div>

                <div  class="text-right px-1 mt-2">
                    {{ customer?.firstname  }}
                </div>
                <div class="text-left px-1  mt-2">
                    {{ customer?.lastname }}
                </div>


            </div>
            <div class="text-center px-1  mt-2">
<!--                {{ customer?.cardId }}-->
            </div>

        </div>
    </div>
</template>

<script>
import {getCreditToEuro} from "@/utils/app_const";

export default{
	name: "bl-ticket",
    methods:{
        goBack(){
            this.$router.go(-1);
        }
    },
    props:{
        order:{
            type:Array,
            required:true
        },
        shop:{
            type:Object,
            required:true
        },
        customer:{
            type:Object,
            required:true
        },
        total:{
            type:Number,
            required:true
        },
        order_finished:{
            type:Boolean,
            required:true
        }
    },
    watch:{
        order_finished(val){
            if(val ===true){
                setTimeout(() => {
                    this.enabled_button = true;
                },2000)
            }
        }
    },
    data(){
      return {
          enabled_button : false
      }
    },
    setup(){
      return {getCreditToEuro}
    }
}
</script>

<style scoped>

</style>
