<template>
  <div class="bg-gradient-to-tr from-slate-800 to-slate-950 py-3 text-center text-xl text-white flex ">
    <span v-if="$route.path !== '/'" class="px-6 mr-auto absolute cursor-pointer" @click="$router.go(-1)"><i class="bi bi-chevron-double-left "></i></span>
    <span class="mx-auto"><i class="mr-5" :class="$route.meta.icon"></i>{{ $route.name ?? $route.path }}</span>
    <button class="cursor-pointer px-6" @click="reload()"><i class="bi bi-arrow-clockwise"></i></button>
  </div>

</template>

<script>
export default {
  methods: {
    reload() {
      this.$router.push('/');
      setTimeout(() => {
        location.reload();
      }, 200);
    }
  },
  name: "header_layout",
}
</script>

<style scoped>

</style>
