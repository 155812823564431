<template>
  <div>
    <div class="my-8 text-center">
      <span class="text-4xl font-bold my-4 mr-4">{{ shop.name }}</span>
    </div>

    <div class="container mx-auto px-5">
      <div class="grid grid-cols-1+ items-center justify-center lg:grid-cols-2 content-center ">
        <menu_button :url="'/store/'+ shop_id +'/create-order'" title="Créez une commande" icon="bi bi-cart-fill"></menu_button>
        <menu_button :url="'/store/'+ shop_id +'/manage-product'" title="Gestion des produits" icon="bi bi-box-seam-fill"></menu_button>

      </div>
    </div>


  </div>
</template>

<script>
import menu_button from "@/components/prefabs/menu_button";
import {useShopStore} from "@/utils/store/shop_store";

export default {
  name: "store_info_page",
  created() {
    this.shop = this.getCurrentShop(this.shop_id);
  },
  data(){
    return {
      shop_id: this.$route.params.shop_id,
      shop: null,
    }
  },
  setup(){
    const shopStore = useShopStore();
    const {getCurrentShop} = shopStore;

    return {getCurrentShop}
  },
  components: {
    menu_button,
  }
}
</script>

<style scoped>

</style>
